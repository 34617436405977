module.exports = [{
      plugin: require('/Users/FallenGo/Workspace/Aginix/aginix-site/node_modules/@wapps/gatsby-plugin-material-ui/gatsby-browser'),
      options: {"plugins":[],"theme":{"typography":{"useNextVariants":true},"palette":{"primary":{"main":"#A0CC3F","light":"#AAD254","dark":"#92BE32","contrastText":"#f4f4f4"},"secondary":{"main":"#0953A0","light":"#0E7DF0","dark":"#042A50","contrastText":"#f4f4f4"}}}},
    },{
      plugin: require('/Users/FallenGo/Workspace/Aginix/aginix-site/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[],"trackingId":"UA-133200423-1"},
    },{
      plugin: require('/Users/FallenGo/Workspace/Aginix/aginix-site/node_modules/gatsby-plugin-offline/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/FallenGo/Workspace/Aginix/aginix-site/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
